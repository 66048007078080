import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const SENTRY_DSN_VALUE = process.env.REACT_APP_SENTRY_DSN_VALUE ?? '';

Sentry.init({
  dsn: SENTRY_DSN_VALUE,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
