import { createInstance } from '@datapunt/matomo-tracker-react';

const MATOMO_SITE_ID = process.env.REACT_APP_MATOMO_SITE_ID ?? '';
const MATOMO_TRACKING_URL = process.env.REACT_APP_MATOMO_TRACKING_URL ?? '';

export const matomoInstance = createInstance({
  urlBase: MATOMO_TRACKING_URL,
  siteId: Number(MATOMO_SITE_ID),
  linkTracking: false, // Important!
});
