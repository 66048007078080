import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import BookIcon from '@/images/book.png';
import { routes } from '@/services/router';
import { TERMS_OF_USE_PATH } from '@/services/router/routes';

import s from './Navigation.module.scss';

interface NavigationProps {
  closeMenu?: (open: boolean) => void;
  className?: string;
}

export const Navigation: React.FC<NavigationProps> = ({ closeMenu, className }) => {
  const location = useLocation();

  const clickHandler = () => {
    if (closeMenu) {
      closeMenu(false);
    }
  };

  return (
    <div className={classnames(s.navigation, className)}>
      <nav className={s.nav}>
        <ul className={s.list}>
          <div className={s.top}>
            {routes.map((route) => (
              <NavLink
                onClick={clickHandler}
                key={route.title}
                to={route.path}
                className={classnames(s.link, { [s.active]: location.pathname === route.path })}
              >
                <li className={s.listItem}>
                  {<route.icon className={s.icon} />}
                  <span className={s.title}>{route.title}</span>
                </li>
              </NavLink>
            ))}
          </div>
          <div className={s.bottom}>
            <a
              onClick={clickHandler}
              href={TERMS_OF_USE_PATH}
              rel="noreferrer"
              target="_blank"
              className={s.link}
            >
              <li className={s.listItem}>
                <img src={BookIcon} alt="book icon" className={s.icon} />
                <span className={s.title}>Terms</span>
              </li>
            </a>
          </div>
        </ul>
      </nav>
    </div>
  );
};
