import React, { FC, useEffect, useState } from 'react';
import { fallDown as Menu } from 'react-burger-menu';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useUpdateEffect } from 'usehooks-ts';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';

import { userApi } from '@/api/userApi';
import { usePistisScoreContract } from '@/blockchain/contracts/usePistisScoreContract';
import { ActionButton } from '@/components/ActionButton/ActionButton';
import { HeaderDisclamer } from '@/components/HeaderDisclamer';
import { Loader } from '@/components/Loader/Loader';
import { Navigation } from '@/components/Navigation/Navigation';
import { ReactComponent as Logo } from '@/images/logo.svg';
import {
  setAuthenticated,
  setTokenId,
  setTokenName,
  setUserAddress,
} from '@/store/slices/userSlice';
import { getAuthSignMessage } from '@/views/Dashboard/Dashboard.contants';

import s from './Layout.module.scss';

export const Layout: FC = () => {
  const { address, isConnected } = useAccount();
  const { data: signature, signMessage, status: signStatus, reset } = useSignMessage();
  const { wallets, tokens } = usePistisScoreContract();
  const { disconnect } = useDisconnect();
  const [menuOpen, setMenuOpen] = useState(false);

  const dispatch = useDispatch();
  const getNonce = async (address: string): Promise<string | undefined> => {
    const response = await userApi.getNonce(address);
    return response?.data.nonce;
  };

  const setSignature = async (address: string) => {
    getNonce(address).then((nonce) => {
      signMessage({
        message: getAuthSignMessage(address, nonce),
      });
    });
  };

  const verifyAuth = async (address: string) => {
    return await userApi.verify(String(address), String(signature));
  };

  useUpdateEffect(() => {
    if (signature && address) {
      verifyAuth(String(address)).then(() => temp(address));
    }
  }, [signature, address]);

  const getUser = async () => {
    try {
      const user = await userApi.getUser();
      if (user) {
        dispatch(setAuthenticated(true));
      }
      return true;
    } catch (e: any) {
      if (e.response.data.statusCode === 401) {
        return false;
      }
    }
  };

  useUpdateEffect(() => {
    document.body.style.overflow = 'hidden';
    menuOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto');
  }, [menuOpen]);

  const getWallets = async (address: string) => {
    return await wallets(address);
  };

  const temp = async (address: string) => {
    const userAuthorized = await getUser();
    dispatch(setTokenName(undefined));
    if (!userAuthorized) {
      dispatch(setAuthenticated(false));
      await setSignature(String(address));
      return;
    }

    getWallets(address).then((res) => {
      if (res.exists) {
        dispatch(setTokenId(Number(res.token)));
        tokens(res.token).then((tokensRes) => {
          if (tokensRes.name) {
            dispatch(setTokenName(tokensRes.name));
          }
        });
      } else {
        dispatch(setTokenId(null));
      }
    });
  };

  useEffect(() => {
    if (isConnected && address) {
      temp(address);
    }
  }, [address, isConnected]);

  useUpdateEffect(() => {
    if (!address) {
      return;
    }
    userApi.logout().then(() => {
      temp(address);
    });
  }, [address]);

  const disconnectHandler = () => {
    disconnect();
    reset();
    dispatch(setUserAddress(''));
  };

  const resignButton = async () => {
    await setSignature(String(address));
  };

  return (
    <div className={s.bg}>
      {signStatus !== 'loading' && signStatus !== 'error' ? (
        <div className={s.layout}>
          <header className={s.header}>
            <HeaderDisclamer text={'alpha version'} />
            <a href="/" className={s.homeLink}>
              <Logo className={s.logo} />
              <div className={s.name}>Pistis</div>
            </a>
            <Menu
              menuClassName={s.menu}
              onClose={() => setMenuOpen(false)}
              onOpen={() => setMenuOpen(true)}
              isOpen={menuOpen}
            >
              <Navigation closeMenu={setMenuOpen} className={s.burgerMenu} />
            </Menu>
          </header>
          <div className={s.title}>Ultimate Destination for Personalized Vehicle Services</div>
          <div className={s.profile}>
            <ConnectButton />
          </div>
          <Navigation className={s.navigation} />
          <main className={s.content}>
            <Outlet />
          </main>
        </div>
      ) : (
        <div className={s.signErrorContainer}>
          <div className={s.logoContainer}>
            <Logo className={s.logo} />
            <div className={s.name}>Pistis</div>
          </div>
          <div className={s.signErrorContent}>
            <div>
              <div className={s.title}>
                {signStatus === 'error' ? 'Cancelled' : 'Communicating with wallet'}
                {signStatus === 'loading' && <Loader className={s.loader} />}
              </div>
            </div>
            <div className={s.description}>
              {signStatus === 'error' &&
                'Looks like you canceled signing of authentication message with your provider'}
              {signStatus === 'loading' &&
                'Please sign the authorization message to use Pistis dApp'}
            </div>
            <div className={s.buttons}>
              {signStatus === 'error' && (
                <ActionButton className={s.button} onClick={resignButton} children="Try Again" />
              )}
              <ActionButton
                variant="outlined"
                className={s.button}
                onClick={disconnectHandler}
                children="Disconnect"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
