import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';

import { usePistisScoreContract } from '@/blockchain/contracts/usePistisScoreContract';
import { ActionButton } from '@/components/ActionButton/ActionButton';
import { Loader } from '@/components/Loader/Loader';
import {
  ADDRESS_TO_ADD_KEY,
  SIGNATURE_TO_ADD_KEY,
  TOKEN_STORAGE_KEY,
} from '@/hooks/useLinkAddress';
import { clientStorage } from '@/services/storage/clientStorage';
import { setLinkAddressConfirmPopupOpened } from '@/store/slices/appSlice';
import { RootState } from '@/store/store';
import { cutWalletPublicId } from '@/utils/utils';

import { Modal } from '../Modal/Modal';

import s from './LinkAddressModal.module.scss';

export const LinkAddressConfirmModal = () => {
  const { addTokenAddress } = usePistisScoreContract();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();

  const { linkAddressConfirmPopupOpened } = useSelector((state: RootState) => state.app);

  const walletAddress = clientStorage.getItem(ADDRESS_TO_ADD_KEY) ?? '';

  const onButtonClick = async () => {
    try {
      setLoading(true);
      const addressToAdd = clientStorage.getItem(ADDRESS_TO_ADD_KEY);
      const addressSignatureToAdd = clientStorage.getItem(SIGNATURE_TO_ADD_KEY);
      const token = clientStorage.getItem(TOKEN_STORAGE_KEY);

      if (!addressToAdd || !addressSignatureToAdd || !token) {
        return;
      }

      await addTokenAddress(token, addressToAdd, addressSignatureToAdd);
      await dispatch(setLinkAddressConfirmPopupOpened(false));
      clientStorage.clear();
      alert.success('Your address was added successfully', { timeout: 5000 });
    } catch (e: any) {
      console.error(e.mesage);
      setLoading(false);
    }
  };

  const onCloseClick = () => {
    dispatch(setLinkAddressConfirmPopupOpened(false));
  };

  const actionButtons = (
    <>
      <ActionButton onClick={onButtonClick}>
        <div className={s.actionButtonContainer}>
          <span>{`${loading ? 'Adding' : 'Add'} this address`} </span>{' '}
          {loading ? <Loader className={s.loader} /> : null}
        </div>
      </ActionButton>
      <ActionButton className={s.closeButton} onClick={onCloseClick}>
        Close
      </ActionButton>
    </>
  );

  return (
    <Modal
      isOpen={linkAddressConfirmPopupOpened}
      title="Hey"
      description={`You are going to link ${cutWalletPublicId(
        walletAddress
      )} address to your Pistis Token.`}
      footer={actionButtons}
    />
  );
};
