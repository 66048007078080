import { createBrowserRouter } from 'react-router-dom';

import { Layout } from '@/components/Layout/Layout';
import { ReactComponent as AboutIcon } from '@/images/about.svg';
import { ReactComponent as DashboardIcon } from '@/images/dashboard.svg';
import { ReactComponent as LoyaltyProgramIcon } from '@/images/loyalty_program-icon.svg';
import { About } from '@/views/About/About';
import { CheckToken } from '@/views/CheckToken/CheckToken';
import { Dashboard } from '@/views/Dashboard/Dashboard';
import { Loyalty } from '@/views/Loyalty/Loyalty';

import {
  ABOUT_ROUTE_PATH,
  CHECK_TOKEN_ROUTE_PATH,
  DASHBOARD_ROUTE_PATH,
  LOYALTY_ROUTE_PATH,
  ROOT_ROUTE,
} from './routes';

export const routes = Object.freeze([
  { path: DASHBOARD_ROUTE_PATH, title: 'Dashboard', icon: DashboardIcon, element: <Dashboard /> },
  {
    path: LOYALTY_ROUTE_PATH,
    title: 'Vehicle Services',
    icon: LoyaltyProgramIcon,
    element: <Loyalty />,
  },
  { path: ABOUT_ROUTE_PATH, title: 'About', icon: AboutIcon, element: <About /> },
]);

export const router = createBrowserRouter([
  {
    path: ROOT_ROUTE,
    element: <Layout />,
    children: [
      {
        path: DASHBOARD_ROUTE_PATH,
        element: <Dashboard />,
      },
      {
        path: LOYALTY_ROUTE_PATH,
        element: <Loyalty />,
      },
      {
        path: CHECK_TOKEN_ROUTE_PATH,
        element: <CheckToken />,
      },
      {
        path: ABOUT_ROUTE_PATH,
        element: <About />,
      },
    ],
  },
]);
