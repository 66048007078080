import { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import { CardWrapper } from '@/components/CardWrapper/CardWrapper';

export const About: React.FC = () => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({ documentTitle: 'About' });
  }, [trackPageView]);

  return (
    <CardWrapper>
      <h1>About</h1>
      <p>
        Pistis is a platform that enables vehicle rental services to customize pricing based on
        client needs, loyalty, reputation, and crypto experience thus growing the client retention.
        Here are the benefits for businesses and customers.
      </p>
      <br /> <br />
      <h3>For Businesses:</h3>
      <ul>
        <li>
          Customize rewards for each client based on loyalty, reputation, and frequency of service
          usage.
        </li>
        <li>Issue tradable coupons with discounts of up to 100%.</li>
        <li>
          Compete effectively with larger players by forming partnerships with rental services in
          other cities and countries.
        </li>
      </ul>
      <br />
      <h3>For Customers:</h3>
      <ul>
        <li>Build reputation by following rental agreement terms and maximize cashback rewards.</li>
        <li>Receive fair rewards from rental services you're loyal to.</li>
        <li>Purchase coupons for up to 100% discounts on car rentals using cryptocurrencies.</li>
      </ul>
      <br />
      <p>
        Find more information about Pistis Network and how it could be used in our{' '}
        <a href="https://docs.pistis.network/">documentation</a>.
      </p>
    </CardWrapper>
  );
};
