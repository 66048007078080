import React from 'react';
import ReactDOM from 'react-dom/client';

import '@/services/sentry';

import { App } from './App';
import { reportWebVitals } from './reportWebVitals';

import '@rainbow-me/rainbowkit/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import '@/assets/styles/index.css';
import '@/assets/styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
