import React, { useCallback, useEffect, useRef, useState } from 'react';
import ModalComponent from 'react-modal';
import { QRCode } from 'react-qrcode-logo';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { ConnectButton, useConnectModal } from '@rainbow-me/rainbowkit';
import * as process from 'process';
import { useOnClickOutside } from 'usehooks-ts';
import { useAccount } from 'wagmi';

import { backend } from '@/api/configs/axios';
import { userApi } from '@/api/userApi';
import { usePistisScoreContract } from '@/blockchain/contracts/usePistisScoreContract';
import { ActionButton } from '@/components/ActionButton/ActionButton';
import { CardWrapper } from '@/components/CardWrapper/CardWrapper';
import { Loader } from '@/components/Loader/Loader';
import { CustomStylesProps } from '@/components/Modal/Modal.types';
import copyImage from '@/images/copy.svg';
import qrImage from '@/images/qr.svg';
import { setTokenId } from '@/store/slices/userSlice';
import { RootState } from '@/store/store';

import s from './Loyalty.module.scss';

export interface ILoyaltyProgram {
  name: string;
  points: number;
}

export interface IUserPointsResponse {
  token: number;
  points: number;
  lastUpdated: string;
  nodeName: string;
}

export const Loyalty: React.FC = () => {
  const { trackPageView } = useMatomo();
  const { token } = useSelector((state: RootState) => state.user);
  const [qrOpened, setQrOpened] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [loading, setLoading] = useState(false);

  const [programs, setPrograms] = useState<any>();
  const ref = useRef<HTMLDivElement>(null);
  const { wallets } = usePistisScoreContract();
  const { isConnected, address } = useAccount();
  const { mint } = usePistisScoreContract();
  const { openConnectModal } = useConnectModal();

  const isProd = process.env.REACT_APP_ENVIRONMENT === 'production';
  const dispatch = useDispatch();

  const { trackEvent } = useMatomo();

  const customStyles: CustomStylesProps = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      minWidth: '248px',
      border: 'none',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '24px',
      padding: '40px 24px',
      background: 'white',
      display: 'flex',
      justifyContent: 'center',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
      wordBreak: 'break-word',
    },
  };

  useEffect(() => {
    trackPageView({ documentTitle: 'Loyalty' });
  }, [trackPageView]);

  useEffect(() => {
    const getLoyaltyPrograms = async () => {
      try {
        const res = await userApi.getLoyaltyPrograms();
        setPrograms(res.data.programs);
      } catch (e) {
      } finally {
        setFetching(false);
      }
    };
    getLoyaltyPrograms();
  }, [address]);

  useOnClickOutside(ref, () => setQrOpened(false));

  const copyHandler = () => {
    navigator.clipboard.writeText(`https://app.pistis.network/customer-points/${token}`);
    toast.success('Copied!');
  };

  const mintToken = useCallback(async () => {
    trackEvent({
      category: 'action',
      action: 'mintButtonClick',
    });
    if (isConnected) {
      setLoading(true);
      const mintingToast = toast.loading(
        'Minting your Pistis Token... Confirm the action with a signature in your wallet'
      );
      try {
        await mint();
        toast.update(mintingToast, {
          render: 'Your token was successfully minted!',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        await wallets(String(address)).then((res) => {
          if (res.exists) {
            dispatch(setTokenId(Number(res.token)));
          }
        });
      } catch (e: any) {
        toast.update(mintingToast, {
          render: 'Something went wrong. Please try later',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      } finally {
        setLoading(false);
      }
    } else {
      openConnectModal && openConnectModal();
    }
  }, [mint, isConnected, trackEvent]);

  return (
    <CardWrapper>
      {address && token ? (
        <>
          <div className={s.control}>
            <div className={s.title}>Vehicle Services</div>
            <div className={s.rightPull}>
              <div onClick={() => setQrOpened(!qrOpened)} className={s.rightPullItem}>
                <img src={qrImage} />
                <span className={s.text}>See code</span>
              </div>
              <div onClick={copyHandler} className={s.rightPullItem}>
                <img src={copyImage} />
                <span className={s.text}>Copy link</span>
              </div>
            </div>
          </div>
          <h1>
            {!fetching ? (
              programs?.length > 0 ? (
                programs?.map((program: ILoyaltyProgram) => (
                  <div key={program.name} className={s.programContainer}>
                    <span className={s.name}>{program?.name}</span>
                    <span className={s.points}>Points: {program?.points}</span>
                  </div>
                ))
              ) : (
                <span className={s.emptyText}>Vehicle Rental services will be displayed here</span>
              )
            ) : (
              <Loader className={s.loader} />
            )}
          </h1>
          <ModalComponent shouldCloseOnEsc style={{ ...customStyles }} isOpen={qrOpened}>
            <div ref={ref} className={s.qrContainer}>
              <div className={s.qrTitle}>QR code of my Pistis token</div>
              <div className={s.subtitle}>
                <span onClick={copyHandler} className={s.copy}>
                  <img src={copyImage} />
                  Copy
                </span>
              </div>
              <QRCode
                logoPadding={6}
                logoPaddingStyle={'square'}
                logoImage={`${backend.defaults.baseURL}/render/token/${token}`}
                value={`${
                  isProd ? 'https://app.pistis.network' : 'app.quvet.com'
                }/customer-points/${token}`}
              />
            </div>
          </ModalComponent>
        </>
      ) : (
        <div className={s.requiredText}>
          <span className={s.title}>Oops!</span>
          <br />
          <span className={s.description}>
            {!address ? (
              <>
                <span className={s.textWithGap}>
                  Looks like you are not connected, connect your wallet to use Pistis Dapp
                </span>
                <br /> <ConnectButton label="Connect wallet" />
              </>
            ) : !token ? (
              <>
                Looks like you have no Pistis Token. Mint it for using Pistis Dapp
                <br />{' '}
                <ActionButton className={s.mintButton} onClick={() => mintToken()}>
                  Mint Free Pistis Token
                </ActionButton>
              </>
            ) : null}
          </span>
        </div>
      )}
    </CardWrapper>
  );
};
