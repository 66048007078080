import React, { FC } from 'react';
import ModalComponent from 'react-modal';
import classNames from 'classnames';

import { CustomStylesProps, ModalProps } from '@/components/Modal/Modal.types';

import s from './Modal.module.scss';

ModalComponent.setAppElement('#root');

const customStyles: CustomStylesProps = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    minWidth: '248px',
    border: 'none',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '24px',
    padding: '40px 66px',
    background: 'rgba(0,0,0, 0.5',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
    wordBreak: 'break-word',
  },
};

export const Modal: FC<ModalProps> = ({
  title,
  description,
  footer,
  isOpen,
  contentLabel = 'Dialog',
  closeTimeoutMS = 500,
  shouldCloseOnOverlayClick = true,
}) => {
  return (
    <ModalComponent
      isOpen={isOpen}
      contentLabel={contentLabel}
      closeTimeoutMS={closeTimeoutMS}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{ ...customStyles }}
    >
      <div className={classNames(s.modalContentContainer)}>
        <div className={s.header}>{title}</div>
        <div className={s.description}>{description}</div>
        <div className={s.buttons}>{footer}</div>
      </div>
    </ModalComponent>
  );
};
