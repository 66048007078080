import {
  PNSOperations,
  PNSOperations__factory as PNSOperationsFactory,
} from '@pistis-score/pistis-protocol-contracts';
import { BigNumber } from 'ethers';
import { useProvider, useSigner } from 'wagmi';

export const PNS_OPERATIONS_CONTRACT_ADDRESS =
  process.env.REACT_APP_PNS_OPERATIONS_CONTRACT_ADDRESS;

export const usePNSOperationsContract = () => {
  const { data: signer } = useSigner();
  const provider = useProvider();
  const contract: PNSOperations = PNSOperationsFactory.connect(
    PNS_OPERATIONS_CONTRACT_ADDRESS,
    signer || provider
  );

  const setPNSName = async (token: BigNumber, name: string) => {
    const setNameResult = await contract.setPNSName(token, name);
    return await setNameResult.wait();
  };

  return {
    setPNSName,
  };
};
