import { positions, Provider as AlertProvider, transitions } from 'react-alert';
// @ts-ignore
import AlertTemplate from 'react-alert-template-basic';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiConfig } from 'wagmi';

import { matomoInstance } from '@/services/matomo';
import { router } from '@/services/router';
import { client, initialChain, whitelistChains } from '@/services/wagmi';
import { store } from '@/store/store';

import 'react-toastify/dist/ReactToastify.css';

const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 8000,
  transition: transitions.SCALE,
};

export const App = () => {
  return (
    // @ts-ignore
    <MatomoProvider value={matomoInstance}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <Provider store={store()}>
          <WagmiConfig client={client}>
            <RainbowKitProvider
              theme={darkTheme()}
              chains={whitelistChains}
              initialChain={initialChain}
              appInfo={{ appName: 'Pistis App' }}
            >
              <RouterProvider router={router} />
              <ToastContainer theme="light" />
            </RainbowKitProvider>
          </WagmiConfig>
        </Provider>
      </AlertProvider>
    </MatomoProvider>
  );
};
