import React, { ChangeEvent, FC, useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { ConnectButton, useConnectModal } from '@rainbow-me/rainbowkit';
import classNames from 'classnames';
import { BigNumber } from 'ethers/lib.esm';
import { useDebounce, useOnClickOutside, useUpdateEffect } from 'usehooks-ts';
import { useAccount } from 'wagmi';

import { backend } from '@/api/configs/axios';
import { usePistisScoreContract } from '@/blockchain/contracts/usePistisScoreContract';
import { usePNSOperationsContract } from '@/blockchain/contracts/usePNSOperationsContract';
import { ActionButton } from '@/components/ActionButton/ActionButton';
import { CardWrapper } from '@/components/CardWrapper/CardWrapper';
import { Loader } from '@/components/Loader/Loader';
import soonImage from '@/images/soon.png';
import { setTokenId, setTokenName } from '@/store/slices/userSlice';
import { RootState } from '@/store/store';

import s from './ScoreCard.module.scss';

interface ScoreCardProps {
  className?: string;
}

export const ScoreCard: FC<ScoreCardProps> = ({ className }) => {
  const { mint, checkNameExists } = usePistisScoreContract();
  const { setPNSName } = usePNSOperationsContract();
  const { isConnected, address } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { token, tokenName } = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState(false);
  const [validNickname, setValidNickname] = useState(true);
  const [editable, setEditable] = useState(false);
  const [isFreeNickname, setIsFreeNickname] = useState(true);
  const [nickname, setNickname] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const { wallets } = usePistisScoreContract();

  const { trackEvent } = useMatomo();

  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const debouncedSearchValue = useDebounce(nickname, 600);

  const dispatch = useDispatch();

  const resetInput = () => {
    if (tokenName && tokenName !== '') {
      setNickname(tokenName);
    } else {
      setNickname('');
    }
  };

  const onOutsideClick = () => {
    if (editable) {
      resetInput();
      setEditable(false);
    }
  };

  useOnClickOutside(ref, onOutsideClick);

  useUpdateEffect(() => {
    const checkNicknameFree = async () => {
      const isBusyNickname = await checkNameExists(debouncedSearchValue);
      setIsFreeNickname(!isBusyNickname);
    };

    const isValidNickname = (nickname: string) => /^[a-z0-9][\w_-]{3,16}[a-z0-9]$/.test(nickname);

    if (isValidNickname(debouncedSearchValue)) {
      setValidNickname(true);
      checkNicknameFree();
    } else {
      setValidNickname(false);
      setInfoMessage('English letters and numbers (5 to 18 characters)');
    }
  }, [debouncedSearchValue]);

  const onInputEdit = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setNickname(inputValue);
  };

  useUpdateEffect(() => {
    if (editable) {
      inputRef?.current?.focus();
    } else {
      setValidNickname(true);
    }
  }, [editable]);

  const mintToken = useCallback(async () => {
    trackEvent({
      category: 'action',
      action: 'mintButtonClick',
    });
    if (isConnected) {
      setLoading(true);
      const mintingToast = toast.loading(
        'Minting your Pistis Token... Confirm the action with a signature in your wallet'
      );
      try {
        await mint();
        toast.update(mintingToast, {
          render: 'Your token was successfully minted!',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        await wallets(String(address)).then((res) => {
          if (res.exists) {
            dispatch(setTokenId(Number(res.token)));
          }
        });
      } catch (e: any) {
        toast.update(mintingToast, {
          render: 'Something went wrong. Please try later',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      } finally {
        setLoading(false);
      }
    } else {
      openConnectModal && openConnectModal();
    }
  }, [mint, isConnected, trackEvent]);

  const saveNickname = async () => {
    if (!validNickname || !isFreeNickname) {
      return;
    }

    if (nickname !== tokenName && nickname !== '') {
      setLoading(true);
      const saveNicknameToast = toast.loading(
        'Saving your new token name... Confirm the action with a signature in your wallet'
      );
      try {
        await setPNSName(BigNumber.from(token), nickname);
        toast.update(saveNicknameToast, {
          render: 'Your new token name was successfully saved!',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        dispatch(setTokenName(nickname));
        setEditable(false);
      } catch (e: any) {
        toast.update(saveNicknameToast, {
          render: 'Something went wrong. Please try later',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
        setEditable(false);
      } finally {
        setLoading(false);
      }
    } else {
      setEditable(false);
    }
  };

  const handleEnterSave = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && editable) {
      saveNickname();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleEnterSave);

    return () => {
      window.removeEventListener('keydown', handleEnterSave);
    };
  }, [handleEnterSave]);

  const getCardContent = React.useCallback(() => {
    if (!isConnected) {
      return (
        <div className={s.center}>
          <span className={classNames(s.text, s.connectText)}>
            Please{' '}
            <div className={s.connectButton}>
              <ConnectButton />
            </div>{' '}
            <span>to start</span>
          </span>
        </div>
      );
    } else if (token === null) {
      return (
        <div className={s.content}>
          <div className={s.text}>
            <span className={s.title}>Pistis Token</span>
            <p className={s.paragraph}>
              The Pistis Token offers a unique solution for storing your reputation on the
              blockchain. By sharing your client reputation with vehicle services, you can receive
              cashback rewards. This innovative approach creates a win-win situation for both
              parties.
            </p>
          </div>
          <div className={s.action}>
            <ActionButton className={s.button} onClick={() => mintToken()}>
              Mint Free Pistis Token
            </ActionButton>
          </div>
        </div>
      );
    } else {
      return (
        <div className={s.content}>
          <div className={s.openseaButton}>
            <img
              className={s.scoreIcon}
              src={`${backend.defaults.baseURL}/render/token/${token}`}
              alt={'opensea link'}
            />
          </div>
          <div className={s.text}>
            {editable && !validNickname && <span className={s.errorHint}>{infoMessage}</span>}
            {editable && !isFreeNickname && (
              <span className={s.errorHint}>Nickname already registered</span>
            )}
            <div className={s.score}>
              <div ref={ref} className={s.action}>
                {editable ? (
                  <input
                    ref={inputRef}
                    maxLength={18}
                    minLength={5}
                    type="text"
                    value={nickname}
                    disabled={!editable}
                    className={classNames(
                      s.input,
                      (!validNickname || !isFreeNickname) && s.error,
                      editable && s.editable
                    )}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      onInputEdit(e);
                    }}
                  />
                ) : (
                  <span>
                    {tokenName && tokenName?.length > 1 ? tokenName : `Your token id: ${token}`}
                  </span>
                )}
                {editable ? (
                  <ActionButton
                    disabled={loading || !validNickname}
                    className={s.button}
                    onClick={saveNickname}
                  >
                    Save nickname
                    {loading ? <Loader className={s.loader} /> : null}
                  </ActionButton>
                ) : (
                  <ActionButton
                    className={s.button}
                    onClick={() => {
                      setEditable(true);
                    }}
                  >
                    {tokenName ? 'Change your handle' : 'Claim your handle'}
                  </ActionButton>
                )}
              </div>
            </div>
            <p className={s.paragraph}>
              Token handles are unique and cannot be used by someone else once picked. You can
              change your handle to any available handle in the future.
            </p>
          </div>
        </div>
      );
    }
  }, [address, token, setNickname, loading, mintToken]);

  return <CardWrapper className={className}>{getCardContent()}</CardWrapper>;
};
