import * as React from 'react';
import { ReactNode } from 'react';
import classNames from 'classnames';

import s from './ActionButton.module.scss';

interface IActionButton {
  children: ReactNode;
  variant?: 'outlined' | 'primary';
}
export const ActionButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & IActionButton
> = ({ children, onClick, disabled, variant, className }) => {
  const style = classNames(
    {
      [s.outlined]: variant === 'outlined' && s.outlined,
    },
    s.button
  );

  return (
    <button disabled={disabled} onClick={onClick} className={classNames(style, className)}>
      {children}
    </button>
  );
};
