import { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import { ExternalProvider, Web3Provider } from '@ethersproject/providers';
import detectEthereumProvider from '@metamask/detect-provider';
import { ethers, Signer } from 'ethers';
import { useAccount } from 'wagmi';

import { usePistisScoreContract } from '@/blockchain/contracts/usePistisScoreContract';
import { clientStorage } from '@/services/storage/clientStorage';
import { setLinkAddressConfirmPopupOpened } from '@/store/slices/appSlice';

export const ADDRESS_TO_ADD_KEY = 'addressToAdd';
export const SIGNATURE_TO_ADD_KEY = 'signatureToAdd';
export const TOKEN_STORAGE_KEY = 'token';
export const ADDRESS_ADD_STATE_KEY = 'switchWithAdd';

export const useLinkAddress = () => {
  const [provider, setProvider] = useState<Web3Provider>();
  const [signer, setSigner] = useState<Signer>();
  const alert = useAlert();
  const { address } = useAccount();
  const { wallets, getTokenAddresses } = usePistisScoreContract();
  const dispatch = useDispatch();

  useEffect(() => {
    const getProvider = async () => {
      const ethereumMetamaskGlobalObject = (await detectEthereumProvider({
        mustBeMetaMask: true,
        silent: true,
      })) as ExternalProvider;

      if (ethereumMetamaskGlobalObject) {
        // Explanation why any param is passed: https://github.com/ethers-io/ethers.js/issues/866
        const provider = new ethers.providers.Web3Provider(ethereumMetamaskGlobalObject, 'any');
        setProvider(provider);
        setSigner(provider.getSigner());
      }
    };

    getProvider();
  }, [address]);

  // switch to new address and ask to sign a message
  const signMessageOnNewAddress = useCallback(async () => {
    if (!address) {
      return;
    }

    const addressToAdd: string | null = clientStorage.getItem(ADDRESS_TO_ADD_KEY);
    const token: string | null = clientStorage.getItem(TOKEN_STORAGE_KEY);

    if (!addressToAdd || !token) {
      return;
    }

    const isAddressAlreadyLinked = await wallets(addressToAdd);
    if (isAddressAlreadyLinked.exists) {
      clientStorage.setItem(ADDRESS_ADD_STATE_KEY, '0');
      alert.info('This address is already linked to another token', { timeout: 5000 });
      return;
    }

    const message = 'Please verify you intent to add address to Pistis token.';

    const messageHash = ethers.utils.solidityKeccak256(
      ['address', 'uint256', 'string'],
      [address, token, message]
    );

    const messageHashBinary = ethers.utils.arrayify(messageHash);

    if (!signer) {
      return;
    }

    const signature = await signer.signMessage(messageHashBinary);

    clientStorage.setItem(SIGNATURE_TO_ADD_KEY, signature);
  }, [address, alert, wallets, signer]);

  // switch to old linked address, run addNewAddress method
  const checkAddressesToAdd = useCallback(
    async (address: string) => {
      const addressToAdd = clientStorage.getItem(ADDRESS_TO_ADD_KEY);
      const addressSignatureToAdd = clientStorage.getItem(SIGNATURE_TO_ADD_KEY);
      const token = clientStorage.getItem(TOKEN_STORAGE_KEY);

      if (!addressToAdd || !addressSignatureToAdd || !token) {
        return;
      }

      const linkedAddresses: string[] = await getTokenAddresses(Number(token));

      if (linkedAddresses.includes(address)) {
        dispatch(setLinkAddressConfirmPopupOpened(true));
      }
    },
    [dispatch, getTokenAddresses]
  );

  // switch to new address and ask to sign a message (initialization)
  useEffect(() => {
    if (address && clientStorage.getItem(ADDRESS_ADD_STATE_KEY) === '1' && provider) {
      clientStorage.setItem(ADDRESS_TO_ADD_KEY, address);
      signMessageOnNewAddress();
      clientStorage.setItem(ADDRESS_ADD_STATE_KEY, '0');
    }
  }, [address, provider, signMessageOnNewAddress]);

  return {
    provider,
    checkAddressesToAdd,
  };
};
