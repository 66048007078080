import { useDispatch, useSelector } from 'react-redux';

import { ActionButton } from '@/components/ActionButton/ActionButton';
import { ADDRESS_ADD_STATE_KEY, TOKEN_STORAGE_KEY } from '@/hooks/useLinkAddress';
import { clientStorage } from '@/services/storage/clientStorage';
import { setLinkAddressQuestionPopupOpened } from '@/store/slices/appSlice';
import { RootState } from '@/store/store';

import { Modal } from '../Modal/Modal';

import s from './LinkAddressModal.module.scss';

export const LinkAddressQuestionModal = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.user);

  const { linkAddressQuestionPopupOpened } = useSelector((state: RootState) => state.app);

  const switchAccount = async () => {
    window.ethereum.request({
      method: 'wallet_requestPermissions',
      params: [
        {
          eth_accounts: {},
        },
      ],
    });
    dispatch(setLinkAddressQuestionPopupOpened(false));
  };

  const onButtonClick = async () => {
    await switchAccount();
    clientStorage.setItem(ADDRESS_ADD_STATE_KEY, '1');
    clientStorage.setItem(TOKEN_STORAGE_KEY, String(token));
  };

  const onCloseClick = () => {
    dispatch(setLinkAddressQuestionPopupOpened(false));
  };

  const description = (
    <div className={s.descriptionContent}>
      <span className={s.descriptionItem}>
        1. Switch to the address you are going to link to your Pistis Token.
      </span>
      <span className={s.descriptionItem}>
        2. Sign the message to confirm the address belongs to you and that you want to link it to
        your Pistis Token.
      </span>
      <span className={s.descriptionItem}>
        3. Get back to the account that is already linked to your Pistis Token.
      </span>
      <span className={s.descriptionItem}>
        4. Confirm the transaction in your wallet to complete the linking of the new address to your
        Pistis Token.
      </span>
    </div>
  );

  const actionButtons = (
    <>
      <ActionButton onClick={onButtonClick}>Choose an account</ActionButton>
      <ActionButton className={s.closeButton} onClick={onCloseClick}>
        Close
      </ActionButton>
    </>
  );

  return (
    <Modal
      isOpen={linkAddressQuestionPopupOpened}
      title="To link another address to your Pistis Token please follow the steps below:"
      description={description}
      footer={actionButtons}
    />
  );
};
