import * as React from 'react';
import classNames from 'classnames';

import s from './Loader.module.scss';

interface LoaderProps {
  className?: string;
}

export const Loader: React.FC<LoaderProps> = (props) => {
  return <div className={classNames(s.loader, props.className)}></div>;
};
