import { FC, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import { LinkAddressConfirmModal } from '@/components/LinkAddressModal/LinkAddressConfirmModal';
import { LinkAddressQuestionModal } from '@/components/LinkAddressModal/LinkAddressQuestionModal';
import { ScoreCard } from '@/components/ScoreCard/ScoreCard';

import s from './Dashboard.module.scss';

export const Dashboard: FC = () => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({ documentTitle: 'Dashboard' });
  }, [trackPageView]);

  return (
    <div className={s.dashboard}>
      <ScoreCard className={s.scoreCard} />
      <LinkAddressConfirmModal />
      <LinkAddressQuestionModal />
    </div>
  );
};
