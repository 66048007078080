import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUserState {
  linkAddressQuestionPopupOpened: boolean;
  linkAddressConfirmPopupOpened: boolean;
}

const initialState: IUserState = {
  linkAddressQuestionPopupOpened: false,
  linkAddressConfirmPopupOpened: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLinkAddressQuestionPopupOpened: (value, action: PayloadAction<boolean>) => {
      value.linkAddressQuestionPopupOpened = action.payload;
    },
    setLinkAddressConfirmPopupOpened: (value, action: PayloadAction<boolean>) => {
      value.linkAddressConfirmPopupOpened = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLinkAddressQuestionPopupOpened, setLinkAddressConfirmPopupOpened } =
  appSlice.actions;

export const appReducer = appSlice.reducer;
