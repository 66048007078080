import React, { FC } from 'react';
import classNames from 'classnames';

import s from './HeaderDisclamer.module.scss';

interface HeaderDisclamerProps {
  text: string;
  className?: string;
}

export const HeaderDisclamer: FC<HeaderDisclamerProps> = ({ text, className }) => {
  return (
    <div className={classNames(s.container, className)}>
      <span className={s.content}>{text}</span>
    </div>
  );
};
