export const DASHBOARD_ROUTE_PATH = '/';
export const ABOUT_ROUTE_PATH = '/about';
export const FAQ_ROUTE_PATH = '/faq';
export const LOYALTY_ROUTE_PATH = '/my-loyalty-program';
export const CHECK_TOKEN_ROUTE_PATH = '/customer-points/:id';

export const TERMS_OF_USE_PATH =
  'https://www.notion.so/Terms-and-conditions-59943ad602ce4e169628eaed5e3adb61';

export const ROOT_ROUTE = DASHBOARD_ROUTE_PATH;
